window.baseUrl = 'https://karty.albedomarketing.pl';

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
window.axios = require('axios');
import VueMask from 'v-mask'
Vue.use(VueMask);
import VueDOMPurifyHTML from 'vue-dompurify-html'
Vue.use(VueDOMPurifyHTML)
Vue.config.productionTip = false;
import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
    config: { id: "G-KBRGEL67EY" }
}, router);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
