<template>
    <div class="main-container main" id="app">
        <div class="text-center mt-5">
            <img src="@/assets/img/logo.svg" alt="Albedo" class="logo">
        </div>
        <main>
            <transition name="scale-fade" mode="out-in">
                <router-view/>
            </transition>
        </main>
        <footer-component/>
        <popup-component/>
    </div>
</template>

<script>
import FooterComponent from "@/components/FooterComponent";
import PopupComponent from "@/components/PopupComponent";

export default {
    name: 'App',
    components: {
        PopupComponent,
        FooterComponent
    }
}
</script>

<style lang="scss">
@import 'src/styles/app';
</style>
